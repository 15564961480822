const messages = {
    emptyName: "contactSection.form.name.error.empty",
    emptyBusiness: "contactSection.form.business.error.empty",
    emptyEmail: "contactSection.form.email.error.empty",
    invalidEmail: "contactSection.form.email.error.invalid",
    emptyMessage: "contactSection.form.message.error.empty",

    success: "contactSection.form.success",
    error: `contactSection.form.error`
}

export default messages