const enFile = {
  translation: {
    header: {
      contactForm: "Contact Form",
      links: {
        home: "Home",
        services: "Services",
        about: "About Us",
        choose: "Why Us",
        team: "Team",
      },
    },
    firstSection: {
      subtitle: "Welcome",
      title: "IT Power for <span>Your Business</span>",
      contact: "Contact us now",
    },
    serviceSection: {
      subtitle: "Services",
      title: "Our <span>Services</span>",
      paragraph: "Immediately available, cost-effective IT services and experts tailored to your business needs. Whether you need assistance with EU regulations/ISO standards compliance, help with cloud migration or cloud computing, support with virtualization, general IT assistance, custom software, or project management and execution – our experts are here to ensure all your IT requirements are met.",
      services: {
        service1: {
          title: "Compliance with EU regulations/ISO standards",
          description: "We help you ensure that your systems comply with EU regulations and ISO standards, providing legal security.",
        },
        service2: {
          title: "Cloud Migration",
          description: "Our experts support you in seamlessly migrating your data and applications to the cloud.",
        },
        service3: {
          title: "Cloud Computing",
          description: "We offer cloud computing services to optimize your IT infrastructure and increase efficiency.",
        },
        service4: {
          title: "Virtualization",
          description: "With our expertise in virtualization, we help you use your IT resources more efficiently and reduce operating costs.",
        },
        service5: {
          title: "Comprehensive Project Management and Execution",
          description: "Our project management team ensures efficient execution and successful implementation of your IT projects.",
        },
        service6: {
          title: "Custom Software",
          description: "We develop customized software solutions specifically tailored to the requirements and goals of your business.",
        },
      },
    },
    aboutSection: {
      subtitle: "About Us",
      title: "Comprehensive <span>IT Solutions</span>, tailored to your needs",
      ticklist: {
        tick1: {
          title: "Trusted IT partner for businesses",
          paragraph: 'Years of experience with IT projects',
        },
        tick2: {
          title: "Comprehensive service offerings to strengthen your organization",
          paragraph: 'From cloud migration and virtualization to customized software solutions and project management – our wide range of services is designed to strengthen your organization and advance your business.',
        },
        tick3: {
          title: "Committed to innovation and customer satisfaction",
          paragraph: 'We are constantly seeking innovative solutions to provide our customers with the best possible service. Your satisfaction is our top priority.',
        },
      },
    },
    teamSection: {
      subtitle: "Team Members",
      title: "Our Team",
      paragraph: "Our young dynamic team helps you achieve your goals",
      team: {
        member1: {
          text: "IT Manager",
        },
        member2: {
          text: "Project Manager",
        },
        member3: {
          text: "Executive Assistant",
        },
        member4: {
          text: "Devops Engineer",
        },
        member5: {
          text: "Devops Engineer",
        },
        member6: {
          text: "Key Account Manager",
        },
        member7: {
          text: "Key Account Manager",
        },
        member8: {
          text: "Key Account Manager",
        },
      },
    },
    chooseSection: {
      subtitle: "Why You Should Work With Us",
      title: "<span>AlpineEdge</span> Your Partner for IT Solutions",
      paragraph: "Rely on AlpineEdge to meet all your IT needs. With our expertise and commitment to excellent service, we are the ideal partner for your business.",
      ticklist: {
        tick1: {
          title: "A panorama of IT solutions",
          paragraph: 'Our team of professionals offers a wide range of services, including cloud migration, software customization, IT support, and project management. Let us help you optimize your business.',
        },
        tick2: {
          title: "Our promise: Excellence and commitment",
          paragraph: 'At AlpineEdge, we always strive to exceed our customers\' expectations. Our commitment to innovation and customer satisfaction is at the core of our company philosophy.',
        },
        tick3: {
          title: "Discover cost advantages",
          paragraph: 'With our cost-effective IT solutions, we meet your specific requirements. Discover the Alpine Edge advantage today.',
        },
      },
    },
    contactSection: {
      subtitle: "Contact",
      title: "Contact Us",
      form: {
        success: "Email sent successfully.",
        error: `Please try again or contact us at: <a href="#">`,
        name: {
          label: "Name",
          placeholder: "Full Name",
          error: {
            empty: "Name is required",
          },
        },
        business: {
          label: "Company",
          placeholder: "Company Name",
          error: {
            empty: "Company name is required",
          },
        },
        email: {
          label: "Email or Phone Number",
          placeholder: "Email or Phone Number",
          error: {
            empty: "Email address is required",
            invalid: "Email address is invalid",
          },
        },
        message: {
          label: "Your Message",
          placeholder: "How can we help you? Please describe your needs briefly, and someone from our team will get back to you as soon as possible.",
          error: {
            empty: "Message is required",
          },
        },
        submit: {
          loading: "Loading...",
          send: "Send",
        },
      },
    },
    footer: {
      main: {
        subtitle: "Contact us",
        title: "Work with <span>AlpineEdge</span> for your IT needs",
        contact: "Contact",
      },
      bottom: {
        paragraph: "We are here for you",
        footerData: {
          column1: {
            header: "Services",
            links: {
              text1: "EU Regulations/ISO Standards",
              text2: "Cloud Migration",
              text3: "Cloud Computing",
              text4: "Virtualization",
              text5: "IT Support",
            },
          },
          column2: {
            header: "About Us",
            links: {
              text1: "About Us",
              text2: "Our Services",
              text3: "Why Us",
              text4: "Contact Us",
            },
          },
          column3: {
            header: "Contact Information",
          },
        },
      },
    },
  },
};

export default enFile;