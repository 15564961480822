import profileImg2 from '../../img/team/Fadel.JPG'
import profileImg1 from '../../img/team/rei.jpg'
import profileImg3 from '../../img/team/Sascha.jpg'
import profileImg4 from '../../img/team/Patrik.JPG'
import profileImg5 from '../../img/team/Arsel.JPG'
import profileImg6 from '../../img/team/Bega.JPG'
import profileImg7 from '../../img/team/Daniele.JPG'
import profileImg8 from '../../img/team/geli.jpg'

const teamData = [
    {
        image: profileImg1,
        name:  "Rei Arifi",
        role: "",
        text: "teamSection.team.member1.text"
    },
    {
        image: profileImg2,
        name:  "Fadel Dia-Eddine",
        role: "",
        text: "teamSection.team.member2.text"
    },
    {
        image: profileImg5,
        name:  "Arsel Arifi",
        role: "",
        text: "teamSection.team.member3.text"
    },
    {
        image: profileImg6,
        name:  "Rei Begaj",
        role: "",
        text: "teamSection.team.member4.text"
    },
    {
        image: profileImg7,
        name:  "Daniele Llazo",
        role: "",
        text: "teamSection.team.member5.text"
    },
    {
        image: profileImg8,
        name:  "Angjelos Braholli",
        role: "",
        text: "teamSection.team.member6.text"
    },
    {
        image: profileImg4,
        name:  "Patrik Kamberi",
        role: "",
        text: "teamSection.team.member7.text"
    },
    {
        image: profileImg3,
        name:  "Sascha Steiger",
        role: "",
        text: "teamSection.team.member8.text"
    },
]

export default teamData
