const ticks = [
    {
        title: "chooseSection.ticklist.tick1.title",
        paragraph:'chooseSection.ticklist.tick1.paragraph'
    }, 
    {
        title: "chooseSection.ticklist.tick2.title",
        paragraph:'chooseSection.ticklist.tick2.paragraph'
    }, 
    {
        title: "chooseSection.ticklist.tick3.title",
        paragraph:'chooseSection.ticklist.tick3.paragraph'
    }
]


export default ticks