const ticks = [
        {
            title: "aboutSection.ticklist.tick1.title",
            paragraph:'aboutSection.ticklist.tick1.paragraph'
        }, 
        {
            title: "aboutSection.ticklist.tick2.title",
            paragraph:'aboutSection.ticklist.tick2.paragraph'
        }, 
        {
            title: "aboutSection.ticklist.tick3.title",
            paragraph:'aboutSection.ticklist.tick3.paragraph'
        }
    ]


export default ticks