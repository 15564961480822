import service1 from '../../img/services/service_1.svg'
import service2 from '../../img/services/service_2.svg'
import service3 from '../../img/services/service_3.svg'
import service4 from '../../img/services/service_4.svg'
import service5 from '../../img/services/service_5.svg'
import service6 from '../../img/services/service_6.svg'

const serviceData = [
    {
      icon: service1,
      title: 'serviceSection.services.service1.title',
      description: 'serviceSection.services.service1.description'
    },
    {
      icon: service2,
      title: 'serviceSection.services.service2.title',
      description: 'serviceSection.services.service2.description'
    },
    {
      icon: service3,
      title: 'serviceSection.services.service3.title',
      description: 'serviceSection.services.service3.description'
    },
    {
      icon: service4,
      title: 'serviceSection.services.service4.title',
      description: 'serviceSection.services.service4.description'
    },
    {
      icon: service5,
      title: 'serviceSection.services.service5.title',
      description: 'serviceSection.services.service5.description'
    },
    {
      icon: service6,
      title: 'serviceSection.services.service6.title',
      description: 'serviceSection.services.service6.description'
    }
];


export default serviceData